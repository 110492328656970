// Code
//
// Inline and block-level code snippets. Includes tweaks to syntax highlighted
// snippets from Pygments/Rouge and Gist embeds.

code,
pre {
  font-family: $code-font-family;
}

code {
  padding: .25em .5em;
  font-size: 85%;
  color: $orange;
  background-color: lighten($orange, 35%);
  border-radius: 3px;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
}

pre code {
  padding: 0;
  font-size: 100%;
  color: inherit;
  background-color: transparent;
}

// Pygments/Rouge via Jekyll
.highlighter-rouge > .highlight {
  background-image: linear-gradient(
    darken($dark, 10%),
    darken($dark, 90%)
  );
}

.highlight {
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: .8rem;
  line-height: 1.4;
  border-radius: 10px;

  pre {
    margin-bottom: 0;
    overflow-x: auto;
  }

  .lineno {
    display: inline-block; // Ensures the null space also isn't selectable
    padding-right: .75rem;
    padding-left: .25rem;
    color: #999;
    // Make sure numbers aren't selectable
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
}

pre {
  max-width: 100%;
  overflow-x: auto;
}
