// for footnotes

.content sup a{  // footnotemark
    color: $orange;
    font-weight: bold;
    &::before { content: '['; }
    &::after { content: ']'; }
}

.fnbar {
    display: inline;
    position: relative;
}

.fncontent {
    position: absolute;
    left: 2em;
    top: 1em;
    width: 30vw;
    background: #fcf8e3;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #faebcc;
    overflow: auto;
    z-index: 100;
    font-size: 0.8em;
    p:last-of-type {
        margin-bottom: 0px;
    }
}