@font-face {
    font-family: "Palatino CDN";
    src: url('https://res.xjtu-blacksmith.cn/fonts/palatino/pala.ttf');
    font-style: normal;
    font-weight: normal;
} 

@font-face {
    font-family: "Palatino CDN";
    src: url('https://res.xjtu-blacksmith.cn/fonts/palatino/palab.ttf');
    font-style: normal;
    font-weight: bold;
} 

@font-face {
    font-family: "Palatino CDN";
    src: url('https://res.xjtu-blacksmith.cn/fonts/palatino/palai.ttf');
    font-style: italic;
    font-weight: normal;
} 

@font-face {
    font-family: "Palatino CDN";
    src: url('https://res.xjtu-blacksmith.cn/fonts/palatino/palabi.ttf');
    font-style: italic;
    font-weight: bold;
} 
