a.fail-stats {
    color: $orange;
    font-weight: bold;
}

.fail-info {
    position: absolute;
    z-index: 100;
    max-width: 20rem;
    font-style: normal;
    background: $gray-1;
    border: solid 2px $red;
    border-radius: 3pt;
    padding: 1ex 1em;
    box-shadow: 0 3px 9px 2px rgba(100, 100, 100, .5);
    color: $gray-5;
    p {
        font-size: 1rem !important;
        font-weight: normal;
        line-height: initial;
        white-space: break-spaces;
    }
    h3 {
        color: $dark;
    }
}