// Typography
//
// Headings, body text, lists, and other misc typographic elements.

h1, h2, h3, h4, h5, h6, .site-title {
  margin-bottom: $heading-spacing;
  font-weight: 600;
  line-height: 1.25;
  color: $heading-color;
  text-rendering: optimizeLegibility;
}

h2, h3, h4 {
  color: $theme;
  a {
      color: inherit;
  }
}

h1 {
  font-size: 2rem;
}

h2 {
  margin: {
      top: 2.5rem;
      bottom: 1rem;
  }
  font-size: 1.5rem;
  padding-bottom: 0.5ex;
  border-bottom: 3px solid $orange;
  &.post-title {
      padding-bottom: unset;
      border-bottom: unset;
  }
}

h3 {
  margin: {
      top: 1.5rem;
      bottom: 1rem;
      left: -0.8rem;
  }
  font-size: 1.25rem;
  font-weight: 600;
  border-left: 5px solid $orange;
  padding-left: calc(0.8rem - 5px);
}

h4, h5, h6 {
  margin-top: 1rem;
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

em {
  font-style: normal;
  background-color: lighten($yellow, 10%);
  color: darken($dark, 15%);
}

strong {
  color: $dark;
}

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
}

hr {
  position: relative;
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff;
}

abbr {
  font-size: 85%;
  font-weight: bold;
  color: #555;
  text-transform: uppercase;

  &[title] {
    cursor: help;
    border-bottom: 1px dotted $border-color;
  }
}

blockquote {
  padding: 1em 2em;
  margin: 1em 0;
  color: $dark;
  border-left: .25rem solid $border-color;
  font-style: normal;
  position: relative;
  padding: 10px 12px;
  border: 3px solid $orange;
  border-radius: 2pt;

  p:last-child {
    margin-bottom: 0;
  }
}


// Markdown footnotes
//
// See the example content post for an example.

// Footnote number within body text
a[href^="#fn:"],
// Back to footnote link
a[href^="#fnref:"] {
  display: inline-block;
  margin-left: .1rem;
  font-weight: bold;
}

// List of footnotes
.footnotes {
  margin-top: 2rem;
  font-size: 85%;
}

// Custom type
//
// relative class
.related, .relatived {
  position: relative;
}

// Extend paragraphs with `.lead` for larger introductory text.

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

// Image and table captions
.caption {
  display: block;
  font-style: unset;
  font-size: 0.9rem;
  color: $body-muted;
  text-align: center;
  margin: 0.5em auto;
  background: unset;
}

// Callout blocks
blockquote.callout {
  background-color: $gray-2;
  border: none;
  padding-left: 2.5em;
  &::before {
      content: "💡";
      position: absolute;
      left: 1em;
  }
}

// taginfo block
blockquote.tag-info {
  background: $gray-1;
  border: solid 2px $gray-5;
  border-left-width: 40px;
  &::before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: -32px;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      content: "！";
      color: #FFF;
      font-size: 22px;
      line-height: 1;
      font-weight: 900;
  }
}

// SVG Icons
a svg, .icon svg {
  height: 1em;
}
a svg { fill: $link-color; }
.icon { vertical-align: middle; }

// LaTeX symbol
.latex, latex {
  font-family: 'Computer Modern', 'Latin Modern', Times, 'Times New Roman', serif;
}
