@import "hydeout";

// fix the color of equation ref.
.mjx-svg-href {
    fill: unset;
    stroke: unset;
}

// fix formula overflow
.MathJax_SVG svg, .MathJax_SVG_Display svg {
    max-width: 100% !important;
}

.content {
    .post-tags {
        margin: 0.5em 0;
        a {
            background: $gray-2;
            padding: 3px 5px 3px 2px;
            border: 2px solid black;
            border-radius: 2px;
            span {
                color: $dark;
            }
        }
    }
    p > img, div.mermaid, table {  // align center
        margin: {
            left: auto;
            right: auto;
        }
        border: 3px solid $theme;
    }
}

#sidebar {
    z-index: 50;
}

header {
    h1.post-title {
        text-align: center;
    }
}

article.post-body {
    border-left: solid 5px $orange;
    padding: 0pt 2em;
    /* indent */
    & > p {
        text-indent: 2em;
        & > img {
            text-indent: 0em;
        }
    }
    .post-body-meta {
        margin-bottom: 1rem;
        position: relative;
    }
}

.post-meta {
    a {
        border-bottom: unset !important;
    }
}

// source: <https://stackoverflow.com/a/35153397>
.video-port {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}

::selection {
    background-color: $theme;
    color: $light;
}

// pdf download box
$theme-trans: rgba($theme, .3);
$cyan-trans: rgba($cyan, .3);
$blue-trans: rgba($blue, .3);
.pdf-download {
    background: linear-gradient(110deg, $theme-trans 0%, $cyan-trans 70%, $blue-trans 100%);
    border: 2px solid $theme;
    box-shadow: 2px 2px 2px 1px;
    border-radius: 5px;
    padding: 2pt 1em;
    width: 18rem;
    max-width: 100%;
    text-align: center;
    margin: auto;
    a:hover {
        color: $orange;
    }
    img {
        display: inline;
        margin-bottom: 0;
        width: 2.5rem;
    }
    span {
        font-size: 2rem;
        vertical-align: bottom;
    }
}